/* eslint-disable react/no-unknown-property */
// @ts-nocheck
import { Colors, Global, Spacing } from '@walter/shared'
import { Input } from '@walter/shared-web'
import { rgba } from 'polished'
import * as React from 'react'
import { Portal } from 'react-portal'
import { Link, useSearchParams } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime, boxShadow, cover } from '../../styles/global'
import { Button, ButtonProps } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { IconButton } from '../IconButton'
import { Loading } from '../Loading'

const Container = styled.div<{ animationState: string }>`
  overflow: auto;
  transition: all ${animationTime} ${animationCurve};
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  ${cover('fixed')};

  ${(props) =>
    props.animationState === 'entering' ||
    (props.animationState === 'entered' &&
      css`
        opacity: 1;
        visibility: visible;
      `)}

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}

  ${(props) =>
    props.animationState === 'exiting' ||
    (props.animationState === 'exited' &&
      css`
        opacity: 0;
        visibility: hidden;
      `)}
`

const Inner = styled.div<{ animationState: string; size: string }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 520px;
  background: ${Colors.white};
  transition: transform ${animationTime} ${animationCurve}, opacity ${animationTime} ${animationCurve};
  z-index: 450;
  background-color: ${Colors.white};
  transform: translateX(7.5%);
  display: flex;
  flex-direction: column;
  ${boxShadow};

  ${(props) =>
    props.size === 'large' &&
    css`
      width: 820px;
    `}

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}
`

const Header = styled.header<{ $richHeader: boolean; backgroundImage: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.large};
  background-color: ${Colors.offWhite};
  ${(props) =>
    props.$richHeader &&
    css`
      height: 200px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url(${props.backgroundImage});
      background-size: cover;
      background-position: center center;
      align-items: flex-start;
    `}
`

const StyledHeading = styled(Heading)<{ $richHeader: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  flex: 1;
  padding-right: ${Spacing.large};
  ${(props) =>
    props.$richHeader &&
    css`
      color: ${Colors.white};
      font-size: 28px;
    `}
`

const Main = styled.main`
  flex: 1;
  overflow-y: auto;
`

const Edit = styled.button`
  padding: ${Spacing.small};
  margin: -${Spacing.small};
  margin-right: ${Spacing.large};
  opacity: 0.75;
  transition: opacity ${animationTime} ${animationCurve};

  &:hover,
  &:focus {
    opacity: 1;
  }
`

const View = styled.button`
  padding: ${Spacing.small};
  margin: -${Spacing.small};
  margin-right: ${Spacing.large};
  opacity: 0.75;
  transition: opacity ${animationTime} ${animationCurve};

  &:hover,
  &:focus {
    opacity: 1;
  }
`

const Close = styled.button``

const DrawerSectionContainer = styled.section`
  padding: ${Spacing.large};
  border-bottom: 1px solid ${Colors.borderColor};

  &:last-child {
    border-bottom: 0;
  }
`

const Background = styled.div`
  background-color: ${rgba(Colors.black, 0.85)};
  ${cover('fixed')};
`

const DrawerIconButton = styled(IconButton)`
  padding: ${Spacing.small};
  margin: -${Spacing.small};
  opacity: 0.75;
  transition: opacity ${animationTime} ${animationCurve};

  &:hover,
  &:focus {
    opacity: 1;
  }
`

const TitleLabel = styled.div`
  ${(props) =>
    props.isEditable &&
    css`
      cursor: pointer;
    `}
`

type DrawerProps = {
  dataTestId?: string
  close: () => void
  children: React.ReactNode
  visible: boolean
  isLoading?: boolean
  title?: string
  subTitle?: React.ReactNode
  size?: string
  handleEditClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleViewClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  actions?: ({ title?: string; label?: string; 'data-tip'?: string } & ButtonProps)[]
  richHeader?: boolean
  backgroundImage?: string
  handleTitleEdited?: (value: string) => void
}

export const Drawer = ({
  dataTestId,
  children,
  title,
  subTitle,
  visible,
  close,
  handleEditClick,
  handleViewClick,
  isLoading,
  size,
  actions,
  richHeader = false,
  backgroundImage = '',
  handleTitleEdited,
  ...rest
}: DrawerProps) => {
  const [searchParams] = useSearchParams()
  const [isEditingTitle, setIsEditingTitle] = React.useState(false)
  const [titleFieldValue, setTitleFieldValue] = React.useState('')
  const titleInputRef = React.useRef()
  const dataTestIdValue = dataTestId ? dataTestId + '_Container' : 'Container'

  React.useEffect(() => {
    const handleSpecialKeys = (event) => {
      if (event.keyCode === 27) {
        // ESC
        close()
      }
    }
    window.addEventListener('keydown', handleSpecialKeys)

    return () => {
      window.removeEventListener('keydown', handleSpecialKeys)
    }
  }, [close])

  const handleTitleClick = () => {
    if (!handleTitleEdited) return
    setTitleFieldValue(title)
    setIsEditingTitle(true)
  }

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditingTitle(false)
      handleTitleEdited(titleFieldValue)
    }
  }

  return (
    <Portal>
      <Transition in={visible} timeout={Global.TRANSITION_LENGTH} unmountOnExit enter>
        {(state) => (
          <Container data-test-id={dataTestIdValue} animationState={state} {...rest}>
            <Inner size={size} animationState={state}>
              <Header data-test-id={`${dataTestId}_Header`} $richHeader={richHeader} backgroundImage={backgroundImage}>
                {searchParams.get('show-back') === 'true' && (
                  <Link to={-1} className="opacity-90 hover:opacity-100 transition-opacity">
                    <Icon icon="left-chevron" size="large" className="mt-0.5 mr-1 -ml-2" />
                  </Link>
                )}
                <StyledHeading $richHeader={richHeader} size={3}>
                  {isEditingTitle ? (
                    <Input
                      value={titleFieldValue}
                      ref={titleInputRef}
                      onChange={(e) => setTitleFieldValue(e.target.value)}
                      onKeyDown={handleTitleKeyDown}
                    />
                  ) : (
                    <TitleLabel
                      data-test-id={`${dataTestId}_Title`}
                      isEditable={!!handleTitleEdited}
                      onClick={handleTitleClick}
                    >
                      {title}
                      {subTitle}
                    </TitleLabel>
                  )}
                  {isLoading && (
                    <div
                      // @ts-ignore
                      css={`
                        display: flex;
                        align-items: center;
                        margin-left: ${Spacing.medium};
                      `}
                    >
                      <Loading type="inline" />
                    </div>
                  )}
                </StyledHeading>
                {!!handleViewClick && (
                  <View data-test-id={`${dataTestId}_View_Button`} onClick={handleViewClick}>
                    <Icon icon="seen" />
                  </View>
                )}
                {!!handleEditClick && (
                  <Edit data-test-id={`${dataTestId}_Edit_Button`} onClick={handleEditClick}>
                    <Icon icon="pencil" />
                  </Edit>
                )}
                {actions && (
                  <div
                    // @ts-ignore
                    css={`
                      /* TMP */
                      margin-right: ${Spacing.large};
                    `}
                  >
                    <ButtonGroup>
                      {actions?.map((action) =>
                        !action.title ? (
                          <DrawerIconButton
                            data-test-id={`${dataTestId}_${action.dataTestId}_Button`}
                            size="small"
                            key={action.icon}
                            {...action}
                          />
                        ) : (
                          <Button
                            dataTestId={`${dataTestId}_${action.title}_Button`}
                            size="small"
                            key={action.title}
                            {...action}
                          >
                            {action.title}
                          </Button>
                        ),
                      )}
                    </ButtonGroup>
                  </div>
                )}
                <Close data-test-id={`${dataTestId}_Close_Button`} onClick={close} data-cy="close-drawer">
                  <Icon color={richHeader ? Colors.white : 'currentColor'} icon="close" />
                </Close>
              </Header>
              <Main data-test-id={`${dataTestId}_Main`}>{children}</Main>
            </Inner>
            <Background onClick={close} />
          </Container>
        )}
      </Transition>
    </Portal>
  )
}

export const DrawerSection = ({
  dataTestId,
  ...props
}: React.ComponentPropsWithoutRef<'section'> & { dataTestId?: string }) => {
  const dataTestIdValue = dataTestId ? dataTestId + '_Section' : 'Section'

  return <DrawerSectionContainer data-test-id={dataTestIdValue} {...props} />
}
